<template>
 <div>
    <v-row no-gutters class="pa-2">
        <v-col cols="12">
          <v-card v-if="!isOnline" flat class="text-center pa-4">
            
            <p style="margin-top: 120px;">
              <v-btn  x-large fab><v-icon>mdi-wifi-off</v-icon></v-btn>
            </p>
              Please connect to the Internet 
          </v-card>
          <v-card v-else>
            
                <v-card-text>
                  <p>
                    Your version: {{current_version}} 
                  </p>
                    <template >
                         <v-alert
                            dense
                            border="left"
                            type="warning"
                          >
                           Application update required!
                          </v-alert>

                          <div class="text-center">
                              <p>Latest version: {{system.version}}</p>
                              <p>Released Date: {{system.last_update}}</p>
                               <!-- <v-btn 
                            class="ma-2" 
                            outlined 
                            :href="system.link" https://bit.ly/3FVLzVV
                            download>
                            <v-icon left>mdi-download</v-icon>
                            Download latest released
                            </v-btn> -->
                            <a :href="system.link" download rel="noopener noreferrer" target="_blank" style="text-decoration: none;">
                                {{system.link}}
                            </a>
                            <br/>
                          <p><br/>
                           <strong> How to:</strong><br/>
                            1) Tap download button or Copy the link then paste to the browser<br/>
                            2) Open "File Folder" <br/>
                            3) Install the latest downloaded .APK file.
                          </p>
                          </div>
                           
                    </template>
                </v-card-text>
          </v-card>     
        </v-col>
    </v-row>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      passform: false,
      conform: false,
        search:'',
        from_date:'',
        to_date: '',
        date_range:"",
        system:{},
        transactions:[],
        per_type:'Overall'
    }),
    created() {
      this.PING()
    
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.get_system_update()
        }
    },
    computed:{
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
     current_version(){
       return localStorage._kake_system_version
     }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
      pasEvent(){
        this.passform = false
        this.conform = false
      },

    get_system_update() {
            this.setRequiredUpdate(false)
            this.$http.get("system_update").then(response => {
              if(response.data.status) {
                 this.system = response.data.system
              }
               }).catch(e => {
                console.log(e.data)
            })
        },
    },
  }
</script>